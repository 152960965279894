<template>
  <iframe
    :src="url"
    height="100%"
    width="100%"
  />
</template>

<script>
export default {
  name: 'IframeSection',
  props: {
    feature: {
      type: Object,
      default: () => ({
        url: '',
        enable: false
      })
    }
  },
  computed: {
    url() {
      let iframeUrl = this.feature.url;
      const { link } = this.$route.query;
      if (link) {
        iframeUrl = this.getDeepLink(iframeUrl, link);
      }
      return iframeUrl;
    }
  },
  created() {
    if (!(this.feature?.url
      && this.feature?.enabled)) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    getDeepLink(mainLink, deepLink) {
      const mainLinkArray = mainLink.split('/');
      const domainArray = mainLinkArray.splice(0, 3);
      return `${domainArray.join('/')}/${deepLink}`;
    }
  }
};
</script>

<style scoped>
iframe {
  border: 0;
}
</style>
