<template>
  <iframe-section
    :feature="feature"
  />
</template>

<script>
import IframeSection from '@/components/IframeSection';

export default {
  name: 'KnowledgeHub',
  components: {
    IframeSection
  },
  computed: {
    feature() {
      return this.$store.getters.company.knowledgeHubData;
    }
  }
};
</script>
